var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.submitForm(reset))}}},[_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"firstName","label":_vm.$t('First Name'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"lastName","label":_vm.$t('Last Name'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})]}}],null,true)})],1),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"type":"email","name":"email","label":_vm.$t('Your e-mail'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_vm._v(" "),_c('SfModal',{attrs:{"visible":_vm.requirePassword,"title":_vm.$t('Attention!'),"cross":"","persistent":""},on:{"close":function($event){_vm.requirePassword = false}}},[_vm._v("\n      "+_vm._s(_vm.$t('Please type your current password to change your email address.'))+"\n      "),_c('SfInput',{staticClass:"form__element",staticStyle:{"margin-top":"10px"},attrs:{"type":"password","name":"currentPassword","label":_vm.$t('Current Password'),"required":""},model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}}),_vm._v(" "),_c('SfButton',{staticClass:"form__button",attrs:{"type":"submit"}},[_vm._v("\n        "+_vm._s(_vm.$t('Update personal data'))+"\n      ")])],1),_vm._v(" "),(_vm.requirePassword)?_c('div',{staticClass:"smartphone-only"},[_vm._v("\n      "+_vm._s(_vm.$t('Please type your current password to change your email address.'))+"\n      "),_c('SfInput',{staticClass:"form__element",staticStyle:{"margin-top":"10px"},attrs:{"type":"password","name":"currentPassword","label":_vm.$t('Current Password'),"required":""},model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}}),_vm._v(" "),_c('SfButton',{staticClass:"form__button",attrs:{"type":"submit"}},[_vm._v("\n        "+_vm._s(_vm.$t('Update personal data'))+"\n      ")])],1):_vm._e(),_vm._v(" "),(!_vm.requirePassword)?_c('SfButton',{staticClass:"form__button",attrs:{"type":"submit"}},[_vm._v("\n      "+_vm._s(_vm.$t('Update personal data'))+"\n    ")]):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }